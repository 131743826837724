import React,{ useEffect,useState } from "react";
import { Badge, Button } from "antd";

import ChatContent from "./ChatContent";
import "./chatsupport.css";
import axios from "../axios";
import { MessageOutlined } from "@ant-design/icons";
import 'antd/dist/antd.css';



const ChatSupport = ({userId}) =>{
    const [ contentOpen, setContentOpen ] = useState(false);
    const [countUnread,setCountUnread] = useState(0);
    const [loaded,setLoaded] = useState(false);



    const loadMessages = async () => {
        const res = await axios.get('/chat-support/all/'+String(userId), {
        });
        if (
            res.status !== 200
        ) {
            throw Error('There was a problem adding you to the event');
        }
        else{
          const data = res.data.data;
          var unread = 0;
          for(const idx in data){
            const msg = data[idx];
                unread+= !msg.user_read ? 1 : 0;
            
          }
          setCountUnread(unread);
        }
    };
    

    useEffect(()=>{
        if(!loaded){
            if(userId){
                loadMessages();
                setLoaded(true);
            }
        }
        
        
    },[userId])

    return (
        <div className={contentOpen ? "chat-support-container-opened" : "chat-support-container"}>
            {!contentOpen ? 
                <Badge count={countUnread}>
                    <Button type="link" shape="circle" onClick={() => {
                        setContentOpen(true);
                        document.body.style.overflow = 'hidden';
                        }}
                        style={{cursor:"pointer"}}
                        >
                            <MessageOutlined style={{fontSize:"2em"}}/>
                    </Button>
                </Badge>
                : 
            <ChatContent setContentOpen={setContentOpen} userId={userId} contentOpen={contentOpen} setCountUnread={setCountUnread}/>
            }
        </div>
        

    );
};

export default ChatSupport;
