import './App.css';
import ChatSupport from './ChatSupport/ChatSupport';
import axios from './axios';
import { useEffect, useState } from 'react';
import 'antd/dist/antd.css';



const App = () => {
  const [anonId,setAnonId] = useState(localStorage.getItem('anonId'));

  const loadAnonId = async () => {
    const res = await axios.get('/chat-support/anon', {
    });
    if (
        res.status !== 200
    ) {
        throw Error('There was a problem adding you to the event');
    }
    else{
      const resposta = res.data;
      localStorage.setItem('anonId', resposta.new_anon);
      setAnonId(resposta.new_anon);
    }
  };

  useEffect(()=>{
    if(!localStorage.getItem('anonId')){
      loadAnonId();
    }
    
  },[]);
  
  return (
    <div>
      {anonId ? <ChatSupport userId={anonId}/> : ''}
    </div>
  );
}

export default App;
