// @flow
import axios from 'axios';

// appUrl is a global variable. See resources/views/index.blade.php
export const BASE_URL = '//localhost:8000/api';

const instance = axios.create({
  baseURL: BASE_URL,
});

instance.interceptors.request.use(config => {
//   const token = localStorage.getItem('token'); // We getting it everytime
  /* eslint-disable no-param-reassign */
  config.headers = {
    // Authorization: `Bearer ${token}`,
    // 'X-CSRF-TOKEN': window.csrfToken,
    'Content-Type': 'application/json',
    Accept: 'application/json',
  };
  return config;
});

export default instance;
