import React,{ useEffect,useState,useRef } from "react";
import { Badge,Button,Input,Spin } from "antd";
import sentitem from '../images/senditem.svg';
import axios from "../axios";
import { CloseOutlined } from "@ant-design/icons";

const { TextArea } = Input;


const ChatContent = ({userId,setContentOpen,contentOpen,setCountUnread}) =>{
    const [messageInput,setMessageInput] = useState('');
    const [sendLoading,setSendLoading] = useState(false);
    const [writingLoading,setWritingLoading] = useState([]);
    const [chatMessages,setChatMessages] = useState([]);
    const scrollable_ref = useRef();

    const loadMessages = async (firstTime) => {
        const res = await axios.get('/chat-support/all/'+String(userId), {
        });
        if (
            res.status !== 200
        ) {
            throw Error('There was a problem adding you to the event');
        }
        else{
          const data = res.data.data;
          var chat = [];
          var unread = 0;
          for(const idx in data){
            const msg = data[idx];
            unread+= !msg.user_read ? 1 : 0;
            const chatMsg = {
              id:msg.id,
              contents: msg.message,
              message_type:msg.from_id == msg.chat_with ? 'send' : 'receive',
              media: false,
              read: msg.user_read
            };
            chat.push(chatMsg);
          }
          setCountUnread(unread);

          if (!chat[chat.length-1].read && chat[chat.length-1].message_type==='receive' && !firstTime){
            readMessages();
            setWritingLoading(chat);
          }
          else if (chat.length!==chatMessages.length){
            setChatMessages([...chat]);
            if (scrollable_ref.current){
              scrollable_ref.current.scrollIntoView({ behavior: "smooth" });
            }
          }
        }
    };

    const readMessages = async () => {
      const res = await axios.post('/chat-support/read-anon/'+String(userId), {
        message:'nothing'
      });
      if (
          res.status !== 200
      ) {
          throw Error('There was a problem adding you to the event');
      }
  };

    const submit = async (userId) => {
      setSendLoading(true);
      const params = new URLSearchParams();
      params.append('chat_with', userId);
      params.append('from_id', userId);
      params.append('message', messageInput);
      const res = await axios.post('/chat-support/send/'+String(userId), {
          chat_with:userId,
          from_id:userId,
          message:messageInput
      });
          
      if (
          res.status !== 200 
      ) {
          setSendLoading(false);
          throw Error('There was a problem adding you to the event');
      }
      loadMessages();
      setMessageInput('');
      setSendLoading(false);
      scrollable_ref.current.scrollIntoView({ behavior: "smooth" });
    };

    useEffect(() => {
      
      if(writingLoading.length>0){
        scrollable_ref.current.scrollIntoView({ behavior: "smooth" });
        const array = writingLoading[writingLoading.length-1].contents.split(" ");
        const seconds = array.length*1000;
        setTimeout(() => {
          setChatMessages([...writingLoading]);
          setWritingLoading([]);
          
          scrollable_ref.current.scrollIntoView({ behavior: "smooth" });
        }, seconds);
      }
      
    },[writingLoading]);

    // on first load, load all messages and scroll down the chat
    useEffect(() => {
      loadMessages(true);
      if (contentOpen){
        readMessages();
      }
        scrollable_ref.current.scrollIntoView({ behavior: "smooth" });
    },[]);

    // keep loading more messages every 10 seconds
    useEffect(() =>{
        setInterval(() => {
            loadMessages();
        }, 10000)
    },[])

    return (
    <div style={{display: "block"}} >
      <div className="chat-box">
        <div className="chat-support-msg-body scrollable">
          {chatMessages &&
            chatMessages.length > 0 ?
            chatMessages.map((msg, i) => (
              <div className="chat-text-container" key={i}>
                {msg.message_type !== 'receive' ? (
                  <div className="receive-bubble">
                    <span className="text">{msg.contents}</span>
                    {msg.media ? <img width={300} height={300} src={msg.media} /> : null}
                  </div>
                ) : (
                  <div className="sent-bubble" style={{backgroundColor:"#fff"}}>
                    <span className="text">{msg.contents}</span>
                    {msg.media ? <img width={300} height={300} src={msg.media} /> : null}
                  </div>
                )}
              </div>
            )) : 
            <div style={{marginLeft:"auto",marginRight:"auto",width:"fit-content"}}>Write a message to clear your doubts</div>}
          {writingLoading.length>0 ? <div style={{marginLeft:"auto",marginRight:"auto"}}>Admin is writing ...</div> : <></>}
          <div ref={scrollable_ref}></div>
        </div>
        <div className="top-title close-btn" onClick={() => {
            setContentOpen(false);
            document.body.style.overflow = 'unset';
            }}>
            <Button 
                style={{marginRight:"auto",marginLeft:"auto"}}
                type="link" 
            >
                <CloseOutlined />
                Close
            </Button>
        </div>
        <div className="wrap-input user-input-fixed">
          
            <div className="icon-info2">
              <TextArea
                autoSize
                style={{
                  width: '100%',
                  background: '#f6f6f6',
                  borderRadius: '15px',
                }}
                className="search-input"
                type="text"
                placeholder="Type your text"
                value={messageInput}
                onChange={(e)=>setMessageInput(e.target.value)}
                onPressEnter={()=>{
                  if(!sendLoading){
                    submit(userId);
                  }
                }}
              />
              <span
                  className="submission "
                  onClick={() => {
                    if(!sendLoading){
                      submit(userId);
                    }
                  }}>
                  {sendLoading ? <Spin/> :<img className="search-icon-inform" src={sentitem} alt="" style={{marginBottom:"10px"}}/>}
              </span>
            </div>
        </div>
      </div>
    </div>
    );
};

export default ChatContent;
